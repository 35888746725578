import {
  getApi,
  postApi,
  getApiWithoutBack,
  deleteApi,
  deleteApiWithoutBack,
  patchApi
} from "@/store/API/api";
import { Action } from "@/store/actionType";
import { CropVarietyComment, CropCategory, CropVarietyReply } from "@/store/models/cropVariety";
import { pathReplacer } from "@/utils/helper.utils";

export default new (class CropVarietyAPI {
  public async getAllCropVarieties(params?: { [key: string]: string }): Promise<any> {
    const url = `${Action.CropVarietiesRangeFiltered}`;
    let query = "";
    if (params) {
      query += `?`;
    }
    for (let param in params) {
      query += `&${param}=${params[param]}`;
    }
    const response = await getApi(url, query);
    return response as any;
  }

  public async getCropVariableSetting(params: {
    metavarid: number;
    croptypeid: number;
  }): Promise<any> {
    const url = `${Action.CropVariableSetting}/${params.metavarid}`;
    let query = "?croptypeid=" + params.croptypeid;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getCropVarietyForAdminById(id: number): Promise<any> {
    const url = `${Action.CropvVarietyForAdmin}/${id}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getRecentCropVarieties(params: { lang?: string }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.lang) {
        query += "lang=" + params.lang;
      }
    }
    const url = `${Action.RecentCropVarieties}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAllCropValue(): Promise<any> {
    const url = `${Action.CropVarietiesRangeFiltered}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getAllCropVariety(): Promise<any> {
    const url = `${Action.CropVarieties}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getPaginatedCropValue(params: {
    page?: any;
    search?: any;
    language?: any;
  }): Promise<any> {
    let query = "/";
    if (params.search || (params.page && params.page !== 0)) {
      query += "?";
    }
    if (params.search) {
      query += "&search=" + params.search;
    }
    if (params.page && params.page !== 0) {
      query += "&page=" + params.page;
    }
    query += "&lang=" + params.language;
    const response = await getApiWithoutBack(Action.CropVarietiesRangeFiltered + query);
    return response as any;
  }

  public async getSearchedCropValue(searchInput: string): Promise<any> {
    const url = `${Action.CropVarietiesRangeFiltered}/?search=${searchInput}`;
    const response = await getApiWithoutBack(url);
    console.log(response);
    return response as any;
  }

  public async getCropVarietyById(obj: any): Promise<any> {
    let query = "/";
    query += "?";
    query += "lang=" + obj.language;
    const url = `${Action.CropVarietiesRangeFiltered}/${obj.id}` + query;
    const response = await getApiWithoutBack(url);
    return response as any;
  }

  public async getAllCropVarietiesDetail(params?: { [key: string]: string }): Promise<any> {
    const url = `${Action.CropVarietiesDetail}`;
    let query = "";
    if (params) {
      query += `?`;
    }
    for (let param in params) {
      query += `&${param}=${params[param]}`;
    }
    const response = await getApi(url, query);
    return response as any;
  }

  public async getCropVarietyDetailById(params: { cropId: number; lang?: string }) {
    let query = "/";
    if (params.lang != null && params.lang != undefined) {
      query += "?lang=" + params.lang;
    }
    const url = `${Action.CropVarietiesDetail}/${params.cropId}${query}`;
    const response = await getApiWithoutBack(url);
    return response as any;
  }

  public async getSimilarCropVariety(params: { cropId: number; lang?: string }) {
    let query = "";
    if (params) {
      query += "?";
      if (params.lang) {
        query += "lang=" + params.lang;
      }
    }
    const url = `${Action.SimilarCropVariety}/${params.cropId}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAllCropType(): Promise<any> {
    const url = `${Action.CropType}`;
    const response = await getApi(url);
    return response as any;
  }

  public async Crops(): Promise<any> {
    const url = `${Action.Crops}`;
    const response = await getApi(url);
    return response as any;
  }

  public async saveCrop(params: any): Promise<any> {
    const url = `${Action.CropName}`;
    const response = await postApi(url, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }

  public async saveCropImage(params: any): Promise<any> {
    const url = `${Action.CropImage}`;
    const response = await postApi(url, params);
    return response as any;
  }

  public async saveCropVariable(params: any): Promise<any> {
    const url = `${Action.CropVariable}`;
    const response = await postApi(url, params);
    return response as any;
  }

  public async getImagesByVarietyId(varietyId: number): Promise<any> {
    const url = `${Action.Images}`;
    const response = await getApi(url, "?cropvarietyid=" + String(varietyId));
    return response as any;
  }

  public async getSupplierCropVariety(params: any): Promise<any> {
    const url = `${Action.SupplierCropVariety}/${params.varietyId}`;
    const response = await getApi(url, "?page=" + String(params.page));
    return response as any;
  }
  public async getAllCropVarietiesMetaVar(): Promise<any> {
    const url = `${Action.CropVarietiesMetaVar}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getAllCropvVarietyForAdmin(language?: string): Promise<any> {
    const url = `${Action.CropvVarietyForAdmin}`;
    const response = await getApi(url, "?lang=" + language);
    return response as any;
  }

  public async saveCropVariety(params: any): Promise<any> {
    const url = `${Action.CropVarieties}`;
    const response = await postApi(url, params);
    return response as any;
  }

  public async saveCropVarietyName(params: any): Promise<any> {
    const url = `${Action.CropVarieties}`;
    const response = await postApi(url, params);
    return response as any;
  }

  public async getAllCropVarietiesList(): Promise<any> {
    const url = `${Action.CropVarietiesValues}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getAllowedCropVarietiesByCropId(id: number): Promise<any> {
    const url = `${Action.DSADCrop}/${id}`;
    const response = await getApi(url);
    return response as any;
  }

  public async saveCropVarietyVariableValue(params: any): Promise<any> {
    const url = `${Action.CropVarietiesValues}`;
    const response = await postApi(url, params);
    return response as any;
  }

  public async updateCropVariety(data: FormData, id: number): Promise<any> {
    const url = pathReplacer(Action.CropVarietiesRetrieve, [{ name: "<id>", value: id }]);
    const response = await patchApi(url, data);
    return response as any;
  }

  public async saveCropVarietyImage(data: any): Promise<any> {
    const url = `${Action.Image}`;
    const response = await postApi(url, data);
    return response as any;
  }

  public async deleteCropVarietyImage(obj: { id: number; cropvarietiesid: number }): Promise<any> {
    const url = `${Action.Image}`;
    const response = await deleteApiWithoutBack(
      url,
      obj.id + "/?cropvarietiesid=" + String(obj.cropvarietiesid)
    );
    return response as any;
  }

  public async deleteCropVariety(id: number): Promise<any> {
    const url = `${Action.CropVarietiesValues}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async getCropVarietyFilterables(cropId: number, lang: string): Promise<any> {
    const url = `${Action.CropVarietiesFilterable}/${cropId}`;
    const response = await getApi(url, "?lang=" + lang);
    return response as any;
  }

  public async getSortableVariables(cropId: number, lang: string) {
    const url = `${Action.SortableVariables}`;
    const response = await getApi(url, "?cropid=" + String(cropId) + "&lang=" + lang);
    return response as any;
  }

  public async getSortedCropVarieties(params: { cropId: number; sorter: string }) {
    const url = `${Action.Sort}`;
    const response = await getApi(
      url,
      "?cropid=" + String(params.cropId) + "&sorter=" + params.sorter
    );
    return response as any;
  }

  // public async getComment(Id: number): Promise<any> {
  //   const url = `${Action.CropVarietyComment}`;
  //   const response = await getApi(url, "?cropvarietyid=" + String(Id));
  //   return response as any;
  // }

  // public async saveComment(comment: CropVarietyComment): Promise<any> {
  //   const url = `${Action.CropVarietyComment}`;
  //   const response = await postApi(url, comment);
  //   return response as any;
  // }

  // public async getReply(commentId: number): Promise<any> {
  //   const url = `${Action.CropVarietyReply}`;
  //   const response = await getApi(url, "?commentid=" + String(commentId));
  //   return response as any;
  // }

  // public async saveReply(reply: CropVarietyReply): Promise<any> {
  //   const url = `${Action.CropVarietyReply}`;
  //   const response = await postApi(url, reply);
  //   return response as any;
  // }

  public async saveCropCarousel(param: {
    id: number;
    carousel: boolean;
    carousel_order: number | null;
  }): Promise<any> {
    const url = `${Action.CropCarousel}`;
    const response = await postApi(url, param);
    return response as any;
  }

  public async getCropCategory(): Promise<any> {
    const url = `${Action.CropCategory}`;
    const response = await getApi(url);
    return response as any;
  }

  public async removeComment(id: number): Promise<any> {
    const url = `${Action.CropVarietyComment}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async removeReply(id: number): Promise<any> {
    const url = `${Action.CropVarietyReply}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async exportPdf(id: number, lang: string = "en"): Promise<any> {
    const url = `${Action.ExportPdf}/${id}`;
    const query = `?lang=${lang}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async saveCropVariableSetting(data: any): Promise<any> {
    const url = `${Action.CropVariableSetting}`;
    const response = await postApi(url, data);
    return response as any;
  }

  public async preRenderCropVariableDetail(params: { id: number; lang: string }): Promise<any> {
    const url = `${Action.PreRenderCropVariableDetail}/${params.id}`;
    const response = await getApi(url, "?lang=" + params.lang);
    return response as any;
  }
  public async getCropVarietiesDocsById(id: number): Promise<any> {
    const url = pathReplacer(Action.CropVarietiesDocs, [{ name: "<id>", value: id }]);
    const response = await getApi(url);
    return response;
  }
  public async editCropVarietiesDocs(id: number, data: FormData): Promise<any> {
    const url = pathReplacer(Action.CropVarietiesDocs, [{ name: "<id>", value: id }]);
    const response = await patchApi(url, data);
    return response;
  }
})();
