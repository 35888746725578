import { getApi, postApi, getApiWithoutBack, deleteApi } from "@/store/API/api";
import { Action } from "@/store/actionType";
import { Province, District, Palika } from "@/store/models/meta";

export default new (class CropAPI {
  public async getAllProvince(params: string = "/"): Promise<any> {
    const url = `${Action.Province}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getAllDistrict(params: string = "/"): Promise<any> {
    const url = `${Action.District}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getAllPalika(params: string = "/"): Promise<any> {
    const url = `${Action.Palika}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getAllBankList(params: string = "/"): Promise<any> {
    const url = `${Action.BankList}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getAllFiscalYear(): Promise<any> {
    const url = `${Action.FiscalYear}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getCropIdBySlug(slug: string): Promise<any> {
    const url = `${Action.CropIdBySlug}/${slug}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getCropVarietyIdBySlug(slug: string): Promise<any> {
    const url = `${Action.CropVarietyIdBySlug}/${slug}`;
    const response = await getApi(url);
    return response as any;
  }
})();
