import { getApi, getApiWithoutBack } from "@/store/API/api";
import { Action } from "@/store/actionType";

export default new (class CropAPI {
  public async getAllCrops(params: { lang?: string }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.lang) {
        query += "lang=" + params.lang;
      }
    }
    const url = `${Action.Crops}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAllCropName(): Promise<any> {
    const url = `${Action.CropName}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getDSADCropName(phase: string, all: boolean = false): Promise<any> {
    const url = `${Action.DSADCrop}`;
    let response: any;
    if (all) {
      response = await getApi(url, "?all=" + all);
    } else {
      response = await getApi(url, "?phase=" + phase);
    }
    return response as any;
  }

  public async getCropById(params: { id: number; lang?: string }): Promise<any> {
    let query = "/";
    if (params.lang != undefined && params.lang != null) {
      query += "?lang=" + params.lang;
    }
    const url = `${Action.Crops}/${params.id}${query}`;
    const response = await getApiWithoutBack(url);
    return response as any;
  }

  public async getCropCategoryNavBar(params: { lang?: string }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.lang) {
        query += "lang=" + params.lang;
      }
    }
    const url = `${Action.CropCategoryNavBar}`;
    const response = await getApi(url, query);
    return response as any;
  }
})();
