import { getApi, postApi, getApiWithoutBack, deleteApi } from "@/store/API/api";
import { Action } from "@/store/actionType";

export default new (class DashboardAPI {
  public async getDashboardData(): Promise<any> {
    const url = `${Action.Dashboard}`;
    const response = await getApi(url);
    return response as any;
  }
  public async getSupplyDemandTrendData(params: string = "/"): Promise<any> {
    const url = `${Action.SupplyDemandTrend}`;
    const response = await getApi(url, params);
    return response as any;
  }
})();
