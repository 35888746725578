import { getApi, getApiWithoutBack } from "@/store/API/api";
import { Action } from "@/store/actionType";

export default new (class CropVarietyAPI {
  public async compareCropDetail(params: { ids: any; lang?: string }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.ids) {
        query += "cropvarietyid=[" + params.ids + "]";
      }
      if (params.lang) {
        query += "&lang=" + params.lang;
      }
    }
    let url = Action.CompareCrops;
    const response = await getApi(url, query);
    return response as any;
  }

  public async downloadCompareCropDetail(params: {
    ids: any;
    lang?: string;
    download?: boolean;
  }): Promise<any> {
    let query = "";
    if (params) {
      query += "?";
      if (params.ids) {
        query += "cropvarietyid=[" + params.ids + "]";
      }
      if (params.lang) {
        query += "&lang=" + params.lang;
      }
      if (params.download) {
        query += "&download=" + params.download;
      }
    }
    let url = Action.CompareCrops;
    const response = await getApi(url, query);
    return response as any;
  }
})();
