import {
  getApi,
  postApi,
  getApiWithoutBack,
  deleteApi,
  deleteApiWithoutBack
} from "@/store/API/api";
import { Action } from "@/store/actionType";

export default new (class UserListAPI {
  public async getAllUserList(): Promise<any> {
    // console.log('reach api')
    const url = `${Action.Register}`;
    const response = await getApi(url);
    // console.log("api");
    // console.log(response);
    return response as any;
  }

  public async getAdminUserList(params: any = "/"): Promise<any> {
    const url = Action.AdminUsers + params;
    const response = await getApiWithoutBack(url);
    return response as any;
  }

  public async getGeneralUserList(params: any = "/"): Promise<any> {
    const url = Action.GeneralUsers + params;
    const response = await getApiWithoutBack(url);
    return response as any;
  }

  public async getSeedCompanyList(params: any = "/"): Promise<any> {
    const url = Action.SeedCompany + params;
    const response = await getApiWithoutBack(url);
    return response as any;
  }

  public async getConsumerCompanyList(params: any = "/"): Promise<any> {
    const url = Action.ConsumerCompany + params;
    const response = await getApiWithoutBack(url);
    return response as any;
  }

  public async registerCompany(params: any) {
    const url = Action.RegisterCompany;
    const response = await postApi(url, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }

  public async EditCompanyProfile(params: any) {
    const url = Action.EditCompanyProfile;
    const response = await postApi(url, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }

  public async getCompanyByUserId(id: number) {
    const url = `${Action.RegisterCompany}/${id}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getCompanyByUser() {
    const url = `${Action.RegisterCompany}`;
    const response = await getApi(url);
    return response as any;
  }

  public async approveCompany(params: any) {
    const url = Action.ApproveCompany;
    const response = await postApi(url, params);
    return response as any;
  }

  public async rejectCompany(params: any) {
    const url = Action.RejectCompany;
    const response = await postApi(url, params);
    return response as any;
  }

  public static async loadUsers(params: any = "/"): Promise<any> {
    const response = await getApiWithoutBack(Action.CustomUser + params);
    return response as any;
  }

  public static async saveUsers(params: any) {
    const response = await postApi(Action.CustomUser, params);
    return response as any;
  }

  public static async loadRoles(): Promise<any> {
    const response = await getApi(Action.Role);
    return response as any;
  }

  public async getUser() {
    const response = await getApi(Action.User);
    return response as any;
  }

  public static async deleteCustomUser(id: number) {
    const response = await deleteApi(Action.CustomUser, id);
    return response as any;
  }

  public async changeProfilePicture(saveData: any): Promise<any> {
    const url = Action.ChangeProfilePicture;
    const response = await postApi(url, saveData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }

  public async deleteCompanyUserById(id: number) {
    const response = await deleteApi(Action.RegisterCompany, id);
    return response as any;
  }

  public async deleteCustomUserById(id: number) {
    const response = await deleteApi(Action.GeneralUsers, id);
    return response as any;
  }

  public async saveAdminUser(params: any): Promise<any> {
    const url = Action.AdminUsers;
    const response = await postApi(url, params);
  }

  public async sendmaillogs(query: string = ""): Promise<any> {
    const url = `${Action.SendMail}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async dsadgroup(): Promise<any> {
    const url = `${Action.SendMailLogGroup}`;
    const response = await getApi(url);
    return response as any;
  }
})();
