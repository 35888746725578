import { getApi, postApi, getApiWithoutBack, deleteApi, patchApi } from "@/store/API/api";
import { Action } from "@/store/actionType";

export default new (class CropAPI {
  public async getAllSeedType(query = ""): Promise<any> {
    const url = `${Action.SeedType}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAllDemands(): Promise<any> {
    const url = `${Action.Demand}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getAllDemandCluster(query: string = ""): Promise<any> {
    const url = `${Action.Cluster}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAdminDemands(query: string = ""): Promise<any> {
    const url = `${Action.AdminDemand}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getDemandSupplyByClusterId(clusterId: number): Promise<any> {
    const url = `${Action.Cluster}/${clusterId}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getSupplyById(id: number): Promise<any> {
    const url = `${Action.AdminSupply}/${id}`;
    const response = await getApi(url);
    return response as any;
  }

  public async postDemand(demand: any): Promise<any> {
    const url = `${Action.Demand}`;
    const response = await postApi(url, demand);
    return response as any;
  }

  public async deleteDemand(id: any): Promise<any> {
    const url = `${Action.Demand}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async confirmDemands(ids: number[]): Promise<any> {
    const url = `${Action.DemandConfirm}`;
    const response = await postApi(url, ids);
    return response as any;
  }

  public async getAdminSupplies(query: string = ""): Promise<any> {
    const url = `${Action.AdminSupply}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAdminSuppliesTotalQuantity(query: string = ""): Promise<any> {
    const url = `${Action.AdminTotalSupplyQuantityAPI}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAdminDemandsTotalQuantity(query: string = ""): Promise<any> {
    const url = `${Action.AdminTotalDemandQuantityAPI}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAllSupplies(): Promise<any> {
    const url = `${Action.Supply}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getAllSupplyCluster(query: string = ""): Promise<any> {
    const url = `${Action.Cluster}`;
    const response = await getApi(url, query);
    return response as any;
  }

  // public async getAllSupplyCluster(params: { page?: any; type?: any }): Promise<any> {
  //   let query = "/";
  //   if (params.type || (params.page && params.page !== 0)) {
  //     query += "?";
  //   }
  //   if (params.type) {
  //     query += "&type=supply";
  //   }
  //   if (params.page && params.page !== 0) {
  //     query += "&page=" + params.page;
  //   }
  //   const response = await getApiWithoutBack(Action.Cluster + query);
  //   return response as any;
  //   // const url = `${Action.Cluster}`;
  //   // const response = await getApi(url, "?type=supply");
  //   // return response as any;
  // }

  public async postSupply(supply: any): Promise<any> {
    const url = `${Action.Supply}`;
    const response = await postApi(url, supply);
    return response as any;
  }
  public async updateAdminSupply(id: number, supply: any): Promise<any> {
    const url = `${Action.AdminSupply}/${id}`;
    const response = await patchApi(url, supply);
    return response as any;
  }

  public async updateAdminDemand(id: number, demand: any): Promise<any> {
    const url = `${Action.AdminDemand}/${id}`;
    const response = await patchApi(url, demand);
    return response as any;
  }
  public async deleteSupply(id: any): Promise<any> {
    const url = `${Action.Supply}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async confirmSupplies(ids: number[]): Promise<any> {
    const url = `${Action.SupplyConfirm}`;
    const response = await postApi(url, ids);
    return response as any;
  }

  public async getPreferences(): Promise<any> {
    const url = `${Action.Preferences}`;
    const response = await getApi(url);
    return response as any;
  }

  public async postPreference(preferences: any): Promise<any> {
    const url = `${Action.Preferences}`;
    const response = await postApi(url, preferences);
    return response as any;
  }

  public async deletePreference(id: any): Promise<any> {
    const url = `${Action.Preferences}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async getSupplier(cropVarietyId: number, seedTypeId: number): Promise<any> {
    let query = "/";
    query = "?cropvarietyid=" + String(cropVarietyId) + "&seedtypeid=" + String(seedTypeId);
    const url = `${Action.Supplier}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getCurrentMarketStat(): Promise<any> {
    const url = `${Action.CurrentMarketStat}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getSupplierStat(query: string = ""): Promise<any> {
    const url = `${Action.SupplierStat}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getSupplierInfo(query: string = ""): Promise<any> {
    const url = `${Action.SupplierInfo}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getSupplierBySlug(slug: string): Promise<any> {
    const url = `${Action.SupplierInfo}/${slug}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getNotices(): Promise<any> {
    const url = `${Action.Notices}`;
    const response = await getApi(url);
    return response as any;
  }

  public async distributionsSold(distributions: any[]): Promise<any> {
    const url = `${Action.Distribution}`;
    const response = await postApi(url, distributions);
    return response as any;
  }

  public async getBalanceSheetReport(query: string): Promise<any> {
    const url = `${Action.BalanceSheetReport}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getUnallocatedReport(query: string): Promise<any> {
    const url = `${Action.UnallocatedReport}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async unsoldAllocationReport(query: string): Promise<any> {
    const url = `${Action.UnsoldAllocationReport}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async unsoldAllocationReportDetail(params: any): Promise<any> {
    const url = `${Action.UnsoldAllocationReportDetail}`;
    const response = await getApi(url, params);
    return response as any;
  }

  public async unsoldAllocationDemandDetail(params: any): Promise<any> {
    const url = `${Action.UnsoldAllocationDemandDetail}`;
    const response = await getApi(url, params);
    return response as any;
  }
})();
