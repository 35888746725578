import { postApi, getApi, getApiWithoutBack, deleteApiWithoutBack } from "@/store/API/api";
import { Action } from "@/store/actionType";
import {
  userInfo,
  userCreate,
  userInRole,
  changeUsername,
  changePassword,
  forgotPassword,
  resetPassword,
  Activate
} from "@/store/models/jwt";

export default new (class JWTAPI {
  public async register(payload: userCreate): Promise<any> {
    const response = await postApi(Action.Register, {
      username: payload.username,
      name: payload.name,
      password: payload.password,
      address: payload.address
    });
    return response as userCreate;
  }

  public async getJWT(payload: userInfo): Promise<any> {
    const response = await postApi(Action.ObtainToken, payload);
    console.log(response);
    return response as userInfo;
  }

  public async getAccessToken(refreshtoken: any): Promise<any> {
    const response = await postApi(Action.RefreshToken, refreshtoken);
    return response as any;
  }

  public async verifyAccessToken(accesstoken: any): Promise<any> {
    const response = await postApi(Action.VerifyToken, accesstoken);
    return response as any;
  }

  public async getUserInRole(params: string = "/"): Promise<any> {
    const response = await getApiWithoutBack(Action.UserInRole + params);
    return response as any;
  }

  public async saveUserInRole(userinrole: userInRole[]): Promise<any> {
    const response = await postApi(Action.UserInRole, userinrole);
    return response as any;
  }

  public async checkAccess(obj: {
    planid: number;
    component: string;
    accesscheck: boolean;
  }): Promise<any> {
    const url =
      Action.CheckAccess +
      "/?planid=" +
      obj.planid +
      "&&component=" +
      obj.component +
      "&&accesscheck=" +
      obj.accesscheck;
    const response = await getApiWithoutBack(url);
    return response as any;
  }

  public async changeUsername(username: changeUsername): Promise<any> {
    const response = await postApi(Action.ChangeUsername, username);
    return response as any;
  }

  public async changePassword(passwords: changePassword): Promise<any> {
    const response = await postApi(Action.ChangePassword, passwords);
    return response as any;
  }

  public async forgotPassword(email: forgotPassword): Promise<any> {
    const response = await postApi(Action.ForgotPassword, email);
    return response as any;
  }

  public async resetPassword(data: resetPassword): Promise<any> {
    const response = await postApi(Action.ResetPassword, data);
    return response as any;
  }

  public async deleteUserInRole(userId: number, roleId: number) {
    let param = userId + "/?roleid=" + roleId;
    const response = await deleteApiWithoutBack(Action.UserInRole, param);
    return response as any;
  }

  public async activate(data: Activate): Promise<any> {
    const response = await postApi(Action.Activate, data);
    return response as any;
  }

  public async social_auth(data: any): Promise<any> {
    const response = await postApi(Action.SocialAuth, data);
    return response as any;
  }

  public async userexists(username: any): Promise<any> {
    const response = await postApi(Action.UserExists, username);
    return response as any;
  }
})();
